/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import icon_linkedin from "../imgs/icon_linkedin.svg"

const LinkedInIcon = props => {
  if (!props.linkedIn) return null
  return (
    <a
      href={`https://www.linkedin.com/in/${props.linkedIn}`}
      className="team__member-linkedin"
    >
      <img src={icon_linkedin} alt="Linkedin" />
    </a>
  )
}

const TeamMember = props => {
  if (props.teamMember)
    return (
      <article className={`team__member ${props.teamMember.className}`}>
        <div className="team__member-avatar">
          <img alt="" src={props.teamMember.avatarsrc} />
        </div>
        <div className="team__member-data">
          <h4 className="team__member-name">{props.teamMember.name}</h4>
          <p className="team__member-position">{props.teamMember.position}</p>
          <p className="team__member-description">
            {props.teamMember.description}
          </p>
          <LinkedInIcon linkedIn={props.teamMember.linkedIn}></LinkedInIcon>
        </div>
      </article>
    )
  return null
}

function isEven(value) {
  if (value % 2 === 0) return true
  else return false
}

const TeamList = props => {
  let size = props.teamList.length
  if (props.limit) {
    size = props.limit
  }
  const list = props.teamList.slice(0, size)
  const teamSize = list.length
  const colSize = 2
  let numRows = 1
  if (teamSize > colSize) {
    numRows = isEven(teamSize)
      ? teamSize / colSize
      : (teamSize - 1) / colSize + 1
  }

  const rows = []
  let index = 0
  for (let i = 0; i < numRows; i++) {
    const teamMember1 = list.shift()
    const teamMember2 = list.shift()
    rows.push(
      <div key={i} className="row">
        <div className="team__members col col__12">
          <TeamMember key={index++} teamMember={teamMember1}></TeamMember>
          <TeamMember key={index++} teamMember={teamMember2}></TeamMember>
        </div>
      </div>
    )
  }
  return rows
}

export default TeamList
