const teamList = [
  {
    name: "René Larrave",
    position: "Chairman",
    position_id: 1,
    description:
      "René has more than 30 years of experience as a management consultant, entrepreneur, investor, and professor. In his spare time, he likes to travel on his motorcycle around the world and has visited more than 100 countries in a quest to better understand the world.",
    avatarsrc: "/images/team/rene_larrave.jpg",
    className: "team__member team__member--chairman",
    linkedIn: "larrave",
    startDate: "01/01/2019",
  },
  {
    name: "Manolo Guerrero",
    position: "CEO",
    position_id: 1,
    description:
      "Manolo is a well known innovation expert and technology strategist with over 25 years of experience. Has led development teams for large corporations, as well as for a few successful startups. In his spare time, he enjoys playing tennis and traveling the world.",
    avatarsrc: "/images/team/manolo_guerrero.jpg",
    className: "team__member team__member--ceo",
    linkedIn: "manoloweb",
    startDate: "01/02/2019",
  },
  {
    name: "Charles Smyth",
    position: "CRO",
    position_id: 1,
    description:
      "Charles has 25 years’ experience in sales, marketing, and customer experience. He enjoys leveraging technology to create operating efficiencies within an organization that allow clients to focus on core competencies. He is an avid motorcyclist, world traveler, and tech diver.",
    avatarsrc: "/images/team/charles_smyth.jpg",
    className: "team__member team__member--ceo",
    linkedIn: "charlessmyth",
    startDate: "01/03/2019",
  },
  {
    name: "Miguel de León",
    position: "VPO",
    position_id: 2,
    linkedIn: "miguel-dlm",
    avatarsrc: "/images/team/miguel_deleon.jpg",
    className: "team__member team__member--dev-manager",
    description:
      "Miguel has over 10 years of experience leading mission critical software development projects around the world. He focuses on developing highly scalable applications with world-class stability and availability. Miguel enjoys coffee, photography, and hiking.",
    startDate: "01/04/2019",
  },
  {
    name: "Jorge Cajas",
    position: "Manager",
    position_id: 3,
    description:
      "Jorge has over 6 years experience developing enterprise solutions, e-banking, web and mobile applications using top tier technologies. He enjoys sharing his knowledge by participating and speaking at different technology events and communities around the world.",
    avatarsrc: "/images/team/jorge_cajas.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "cajasmota",
    startDate: "09/23/2019",
  },
  {
    name: "Kevin Gomez",
    position: "Manager",
    position_id: 3,
    description:
      "Kevin is a software engineer passionate about learning algorithms and data structures. He enjoys challenges that involve working with multiple stacks and process automation. When Fernando is not coding, he can be found playing video games online or watching football.",
    avatarsrc: "/images/team/kevin_gomez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "fgomez-sierra",
    startDate: "10/01/2019",
  },
  {
    name: "Ricky Yancor",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Ricky is a resourceful software engineer who loves to produce high-quality solutions. He has a technical depth that often surpasses that of more seasoned developers. He loves coaching and teaching others the tricks of the technology world.",
    avatarsrc: "/images/team/ricky_yancor.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "rickyyancor",
    startDate: "08/01/2019",
  },
  {
    name: "Juanluis Estrada",
    position: "Manager",
    position_id: 3,
    description:
      "Juanluis is a software engineer who specializes in test-driven development and automation, including continuous integration tools and best practices. He enjoys rock music and playing video games.",
    avatarsrc: "/images/team/juanluis_estrada.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "juanluis-estrada",
    startDate: "11/11/2019",
  },
  {
    name: "Edgar Giron",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Edgar is an authentic innovator, always thinking about opportunities to improve things. He has extensive experience working with both startups and large businesses in creating things from scratch. He believes team work is key to successful technology projects. In his spare time, he enjoys playing field hockey, reading books, and playing musical instruments. He is also an avid stock trader always looking for the next big investment opportunity.",
    avatarsrc: "/images/team/edgar_giron.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "edgargiron1996ai",
    startDate: "02/08/2021",
  },
  {
    name: "Andrea Alvarez",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Andrea is a software engineer who enjoys writing high-quality code and experimenting with new technologies. She is a great critical thinker who helps teams map and design the best solutions from different scenarios. She enjoys playing online multiplayer video games and meeting people from around the world.",
    avatarsrc: "/images/team/andrea_alvarez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "andrea-alvarez-gt",
    startDate: "05/24/2021",
  },
  {
    name: "Allan Búrbano",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Allan is a proactive, driven, and results-oriented engineer who enjoys technology, especially when a compelling business case supports it. He loves open source tools and initiatives and has over seven years of experience in leading edge software development. He enjoys riding his motorcycle and listening to good music.",
    avatarsrc: "/images/team/allan_burbano.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "aburbano39",
    startDate: "02/01/2021",
  },
  {
    name: "Alejandro Tamayo",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Alejandro is passionate about new software development technologies. He has over eight years of experience developing applications and working as a mentor for up and coming web and mobile application developers. He enjoys swimming, music and playing video games.",
    avatarsrc: "/images/team/alejandro_tamayo.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "alejandro-tamayo-a73a9011a",
    startDate: "03/29/2021",
  },
  {
    name: "Marvin Alvarado",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Marvin loves software engineering and enjoys learning from others. He has built both web and mobile apps for multiple international companies, always leveraging the latest best practices. In his spare time, he likes playing sports and spending time with his family.",
    avatarsrc: "/images/team/marvin_alvarado.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "mnalvaradox",
    startDate: "05/17/2021",
  },
  {
    name: "Daniel Lara",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Daniel is a software developer mainly focused on the backend. He has a passion for teaching. He leads software development communities and is constantly thinking about how technology can help improve lives. In his spare time, he enjoys following the latest development with blockchain, augmented reality and crypto assets.",
    avatarsrc: "/images/team/daniel_lara.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "daniellarap",
    startDate: "02/01/2021",
  },
  {
    name: "Hugo Castillo",
    position: "Manager",
    position_id: 3,
    description:
      "Hugo is a senior software engineer with extensive experience developing and designing complex systems and leading development teams. He loves traveling, skateboarding, video games, and spending time with friends and family.",
    avatarsrc: "/images/team/hugo_castillo.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "hc4stillo",
    startDate: "11/19/2020",
  },
  {
    name: "José Estrada",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Jose is a technology enthusiast.  He is passionate about using the latest technologies to build applications that automate and improve people's lives and business operations.  He enjoys traveling, hiking, camping, and everything related to agriculture.",
    avatarsrc: "/images/team/jose_estrada.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "esvin-jose-estrada",
    startDate: "10/18/2021",
  },
  {
    name: "Bryan Valenzuela",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Bryan is a software engineer with more than 6 years of development experience in mobile, web frontend and backend using a variety of technologies. He likes to learn new technologies and to be up-to-date with all the latest developments. He enjoys playing video games, building Lego toys, playing football and watching movies.",
    avatarsrc: "/images/team/bryan_valenzuela.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "bryanvalenzuela",
    startDate: "11/22/2021",
  },
  {
    name: "Erick Hernandez",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Erick is a software engineer focused on web and mobile solutions.  He loves developing enjoyable and easy to use solutions to give a best-in-class user experience. Erick enjoys cooking, going to the cinema, playing video games and playing the guitar.",
    avatarsrc: "/images/team/erick_hernandez.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "erick-fernando-hernandez-arroyo-23103a222",
    startDate: "03/01/2022",
  },
  {
    name: "Manuel Fuentes",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Manuel is a full-stack software engineer with expertise in designing and building secure and highly scalable software solutions using the latest frameworks and technologies. He enjoys music, movies, playing video games and traveling.",
    avatarsrc: "/images/team/manuel_fuentes.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "manu13580",
    startDate: "02/21/2022",
  },
  {
    name: "William Valladares",
    position: "Software Engineer",
    position_id: 5,
    description:
      "William is a full-stack software engineer with experience in academia and industry. He enjoys learning and keeping up with the latest software engineering developments.  He likes playing video games and e-sports, listening to music, and watching football.",
    avatarsrc: "/images/team/william_valladares.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "wvalladares",
    startDate: "09/20/2021",
  },
  {
    name: "Derek Menendez",
    position: "Manager",
    position_id: 3,
    description:
      "Derek is passionate about technology and entrepreneurship. He loves developing well architected software that delivers highly available and scalable infrastructure solutions. When Derek is not writing code, he is either playing Age of Empires or learning to play a musical instrument.",
    avatarsrc: "/images/team/derek_menendez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "derek-urizar-8bb0a9a2",
    startDate: "05/10/2021",
  },
  {
    name: "Jennifer Rodriguez",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Jennifer is a software engineer who enjoys creating innovative software solutions. She believes in teamwork and in the power of sharing knowledge. In her spare time, she enjoys exercising. learning new technologies, and spending time with family.",
    avatarsrc: "/images/team/jennifer_rodriguez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "jennifer-rodriguez-52b273201",
    startDate: "02/21/2022",
  },
  {
    name: "Mynor Xico",
    position: "Software Engineer",
    position_id: 4,
    description:
      "Mynor is a full stack developer mainly focused on the backend. He is passionate about continuously learning new technologies and looking for social impact opportunities using technology.  When he is not coding, he is practicing piano, playing video games or going out for a walk.",
    avatarsrc: "/images/team/mynor_xico.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "mynorxico",
    startDate: "03/01/2022",
  },
  {
    name: "Pedro Cáceres",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Pedro is an accomplished software developer with more than 9 years of experience. Converting coffee into source code is his hobby and his passion. He also loves motorcycles and rainy days.",
    avatarsrc: "/images/team/pedro_caceres.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "pedro-c%C3%A1ceres",
    startDate: "11/08/2021",
  },
  {
    name: "Pablo Medrano",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Pablo is a seasoned software engineer who enjoys learning new things. He is comfortable in all technology areas from coding, to networking, to DevOps, to cloud technologies.  Pablo loves swimming, teaching and coaching others, science fiction, music, video games, and learning about computer hardware.",
    avatarsrc: "/images/team/pablo_medrano.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "pablo-medrano-7726a2190",
    startDate: "12/01/2021",
  },
  {
    name: "Erick Damián",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Erick is an experienced full stack software developer with an emphasis in the backend who enjoys exploring new technologies. He is a fast learner and has the ability to quickly get the most out of new technologies. In his spare time, he enjoys video games and reading about almost anything.",
    avatarsrc: "/images/team/erick_damian.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "erickdamian",
    startDate: "12/01/2021",
  },
  {
    name: "Luis Rivera",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Luis is an experienced DevOps engineer with experience in developing and designing well structured software systems. He is a tech enthusiast and an avid cinephile. He loves video games and spending time with friends and family.",
    avatarsrc: "/images/team/luis_rivera.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "luis-rivera-697320120",
    startDate: "10/18/2021",
  },
  {
    name: "David García",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "David has over 7 years of experience developing software solutions and is always eager to take on new technology challenges. He is passionate about software and hardware. He is a critical thinker who enjoys coming up with simple and elegant solutions to solve complex problems. He loves pizza, coffee and videogames.",
    avatarsrc: "/images/team/david_garcia.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "david-garcia-1bb04b100",
    startDate: "12/01/2021",
  },
  {
    name: "Christian Altan",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Christian is a passionate software engineer.  He believes that the most successful projects are possible only by paying attention to the small details.  In his spare time, he enjoys driving, music, and everything technology.",
    avatarsrc: "/images/team/christian_altan.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "christian-davel-altan-garrido-614ab21ab",
    startDate: "01/04/2021",
  },
  {
    name: "Carla Larios",
    position: "Office Manager",
    position_id: 6,
    description:
      "Carla is a seasoned business administrator and leads MangoChango's internal operations. Prior to MangoChango,  she led the online operations for one of most important newspapers in Central America for over 10 years. She loves dogs and enjoys traveling the world.",
    avatarsrc: "/images/team/carla_larios.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "",
    startDate: "01/01/2020",
  },
  {
    name: "Isaac Ventura",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Isaac is a seasoned software engineer with a strong background in enabling client’s strategic endeavors through the effective use of technology.  He has deep expertise in .net technologies, Angular, Node.js, React and SQL and NoSQL databases.",
    avatarsrc: "/images/team/isaac_ventura.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "isaac-gonzalez-700670168",
    startDate: "04/04/2022",
  },
  {
    name: "Rodrigo Pineda",
    position: "Manager",
    position_id: 3,
    description:
      "Rodrigo is an expert level engineer with experience in delivering up-to-date architectures and technology solutions that are highly maintainable and scalable. He has worked with some of the leading software companies in the world, evaluating, designing, and delivering world class customer facing apps using the latest technologies and frameworks.",
    avatarsrc: "/images/team/rodrigo_pineda.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "rodrigo-pineda-810702a2",
    startDate: "04/25/2022",
  },
  {
    name: "Diego Moir",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Diego is a software engineer mainly focused on front end development leveraging the latest technologies and frameworks. He enjoys learning and developing well structure and scalable software architectures. In his spare time, he likes to travel and just hang out with friends.",
    avatarsrc: "/images/team/diego_moir.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "diego-josué-pérez-moir-59764b195",
    startDate: "11/07/2022",
  },
  {
    name: "Diego Calderón",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Diego is an experienced full-stack software engineer including mobile development.  He enjoys learning new technologies and coaching others on the use of best tools, frameworks, and practices.  He enjoys traveling, taking pictures, and watching movies in his spare time.",
    avatarsrc: "/images/team/diego_calderon.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "diegocdl",
    startDate: "02/01/2023",
  },
  {
    name: "Dennis Castro",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Dennis is a seasoned software developer in both front end and back end technologies.  He enjoys learning and applying cutting-edge technologies to solve complex business challenges.  In his spare time, he spends time with his family, playing soccer and video games, and listening to music.",
    avatarsrc: "/images/team/dennis_castro.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "dennis-castro-bb1401ab",
    startDate: "01/02/2023",
  },
  {
    name: "Carlos Mejía",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Carlos is a full stack developer with more than 7 years of experience developing software solutions in a variety of sectors including health care, marketing intensive businesses, professional services, and more. He enjoys learning new things, trying out new tools and frameworks, and facing technology challenges.  He enjoys playing video games, watching movies and sports, and reading books.",
    avatarsrc: "/images/team/carlos_mejia.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "carlos-mejia-3500a21b3",
    startDate: "01/02/2023",
  },
  {
    name: "Gil Alvarado",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Gil is a full stack software developer with an emphasis on backend and cloud development.  He loves simplifying and scaling processes, technology-based solutions, and workflows. In his spare time, he enjoys staying active in a variety of sports.",
    avatarsrc: "/images/team/gil_alvarado.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "gildardo-alvarado-9ab60b1aa",
    startDate: "03/27/2023",
  },
  {
    name: "Luis Navas",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Luis is a full stack software developer with an emphasis on frontend development. He has more than 15 years of experience in web development, responsive web, and mobile application development. He enjoys learning about new technologies and approaches. In his spare time, he enjoys spending time with his family, traveling, playing video games, and watching soccer.",
    avatarsrc: "/images/team/luis_navas.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "luisf-navas",
    startDate: "05/01/2023",
  },
  {
    name: "Leo Menendez",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Leo is a full stack software developer and an experienced project leader with over 15 years of experience building well architected and scalable technology solutions in a variety of industries such as financial services, telecom, and ecommerce among others.  Throughout his career, he has mastered multiple programming languages, DBMSs as well as CI/CD and unit testing approaches and technologies.",
    avatarsrc: "/images/team/leo_menendez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "leonidasmenendez",
    startDate: "04/03/2023",
  },
  {
    name: "Sergio Penagos",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Sergio is a Data Scientist with a strong background in software development. Throughout his professional career he has built and helped deploy numerous mission critical applications. He loves to learn new technologies and is always willing to help anyone that needs a hand. In his spare time Sergio enjoys exercising and producing music.",
    avatarsrc: "/images/team/sergio_penagos.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "sergio-penagos-057327181",
    startDate: "05/08/2023",
  },
  {
    name: "Enoc Soto",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Enoc has a strong background in DevOps, AWS services, and CI/CD pipelines. He is passionate about quality, attention to detail, continuous learning, and exploring new technology paradigms and tools to solve problems.  He enjoys camping, trekking, and all outdoor activities.",
    avatarsrc: "/images/team/enoc_soto.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "enoc-soto-aa3a10261",
    startDate: "05/08/2023",
  },
  {
    name: "Leonel Rojas",
    position: "Architect",
    position_id: 3,
    description:
      "Leonel is a highly skilled Software Engineer and Solutions Architect with a passion for technology and adventure. Leonel has more than 15 years of experience working at Fortune 500 companies, honing his craft.  He loves building innovative and scalable software solutions that meet the needs of businesses of all sizes. He spends his personal time playing board games, video games, cycling and traveling.",
    avatarsrc: "/images/team/leonel_rojas.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "leonel-rojas-89769756",
    startDate: "05/08/2023",
  },
  {
    name: "Andres Morales",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Andres is an experienced full-stack engineer with a passion for cloud computing and its potential in shaping the future of technology. He has been involved in software development since high school and loves to move with technology as it changes and develops.  In his spare time, he enjoys watching movies and learning from interesting people with different backgrounds and perspectives.",
    avatarsrc: "/images/team/andres_morales.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "jose-andres-morales-calderon-9a82458b",
    startDate: "04/03/2023",
  },
  {
    name: "Chris Hardaway",
    position: "Business Development Manager",
    position_id: 2.5,
    description:
      "Chris has over 10 years of experience in sales and marketing of complex technology-based solutions. He has worked for both software (SaaS) and technology services companies (e.g., Cloud) working with senior executives in developing technology-driven strategies and solutions. He has a BS in Social Sciences from Kansas State University and enjoys golf and traveling in his spare time.",
    avatarsrc: "/images/team/chris_hardaway.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "chris-hardaway-a1012bb0",
    startDate: "07/17/2023",
  },
  {
    name: "Carlos Aguilar",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Carlos is a seasoned full stack web developer with extensive knowledge of leading technologies, tools, and frameworks.  He is also an experienced mobile application and deep/machine learning developer. He is passionate about learning and discovering new technologies that improve technology deployment productivity.",
    avatarsrc: "/images/team/carlos_aguilar.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "carlos-aguilar-a1b386213",
    startDate: "03/20/2023",
  },

  {
    name: "Vittorio Toriello",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Vittorio has more than 9 years of fullstack and mobile development for both startups and large businesses. He enjoys the challenges of ew projects with complex problems to solve. Vittorio loves learning about new technologies and how to best apply them to new technical challenges.  In his spare time, he enjoys practicing contact sports, playing board games, and reading.",
    avatarsrc: "/images/team/vittorio_toriello.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "vittorio-toriello",
    startDate: "08/04/2023",
  },
  {
    name: "Lino García",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Lino is a software engineer with 20+ years of experience in designing, building, and delivering software solutions. He is an expert in applying agile principles to optimize the systems development life cycle, promote CI/CD, and foster a collaborative team environment.  He is a Certified Scrum Master and Product Owner. Outside work, he enjoys chess, 80s music, movies, and time with his cat, Mish@.",
    avatarsrc: "/images/team/lino_garcia.png  ",
    className: "team__member team__member--dev-manager",
    linkedIn: "linogarciavallejo",
    startDate: "08/04/2023",
  },
  {
    name: "Brayan Hernandez",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Brayan is a full-stack developer who enjoys working on impactful projects for clients across industries. He enjoys learning new technologies and being an active participant in knowledge-sharing communities. In his free time, he enjoys exploring nature, playing video games, and playing basketball.",
    avatarsrc: "/images/team/brayan_hernandez.jpeg",
    className: "team__member team__member--dev-manager",
    linkedIn: "brayan-hernandez-4b831bb7",
    startDate: "09/05/2023",
  },
  {
    name: "Mario Alvarado",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Mario is a full-stack software engineer with over 8 years of experience in software development. He is highly skilled in a number of programming languages, frameworks, and technologies and is committed to continuous learning, innovation, and exceeding expectations",
    avatarsrc: "/images/team/mario_alvarado.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "mario-alvarado",
    startDate: "09/05/2023",
  },
  {
    name: "Jorge Castañeda",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Jorge is a skilled software engineer with a background in academia and industry. He is passionate about delving into the latest technological advancements, particularly in coding and cloud computing. In his spare time, he enjoys staying active in a variety of sports.",
    avatarsrc: "/images/team/jorge_castaneda.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "jorge-mario-castaneda-mendez",
    startDate: "10/16/2023",
  },
  {
    name: "Pablo Cáceros",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Pablo is an experienced and passionate Software Engineer who loves to create innovative solutions. He has a strong background in designing, developing and deploying multi-tenancy web applications and  building services based on microservice architectures in the financial sector. He is always looking for the simple solution to solve complex problems. When he's not coding, you can catch him playing video games and doing sports with my family and friends",
    avatarsrc: "/images/team/pablo_caceros.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "pablocaceros27000",
    startDate: "10/16/2023",
  },
  {
    name: "Fernando Pensamiento",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Fernando is an experienced full-stack software engineer. He is passionate about learning new technologies to solve tough business problems and technical challenges.  In his free time, he enjoys riding his bicycle, doing night volcano climbs, playing video games, and spending time with his friends.",
    avatarsrc: "/images/team/fernando_pensamiento.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "fpensa123",
    startDate: "01/15/2024",
  },
  {
    name: "Daniel Herrera",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Daniel has extensive experience in relational database modeling, implementation, and tuning.  He has built highly secure ETL pipelines for high-concurrency data on the cloud and is proficient with .Net, Java, Python, and JavaScript frameworks. In his spare time, he enjoys DIY electronics projects and walking outdoors.",
    avatarsrc: "/images/team/daniel_herrera.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "daniel-herrera-47b876186",
    startDate: "09/18/2023",
  },
  {
    name: "Erick Morales",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Erick is a full-stack software engineer with a strong background in backend development using the latest technologies and frameworks. He also has a deep understanding of CI/CD and SCRUM.      Throughout his career, he has developed technology solutions in industries that include financial services, transportation and logistics, business services, and food. He enjoys facing new challenges, learning new technologies, watching movies, and playing video games.",
    avatarsrc: "/images/team/erick_morales.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "dev-erick-morales",
    startDate: "11/01/2023",
  },
  {
    name: "Otto Gramajo",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Otto is a seasoned full-stack developer with a passion for technology and learning.  He is one of those engineers always one step ahead when it comes to emerging technologies and trends. His dedication to his craft is evident in his meticulous approach to his work and to the smallest details. In his spare time, he enjoys listening to music, hanging out with friends, and playing video games.",
    avatarsrc: "/images/team/otto_gramajo.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "otto-gramajo-2730571a3",
    startDate: "01/08/2024",
  },
  {
    name: "Vinicio Herrarte",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Vinicio is a full-stack engineer with experience in multiple front-end and back-end technologies such as Java, Node.js, React, Angular, and .NET. He enjoys learning new tools and leveraging agile methodologies. He has experience developing technology solutions for large multinationals in financial services, pharmaceuticals, and education.  In his spare time, he enjoys adventure travel.",
    avatarsrc: "/images/team/vinicio_herrarte.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "vinicio-herrarte-barrios-bb1915134",
    startDate: "24/05/2023",
  },
  {
    name: "Erick Bamaca",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Erick is a senior software engineer passionate about accessibility and developing web solutions. He is a seasoned practitioner with experience in leading large and complex technology projects, providing consulting, and enabling cross-functional collaboration.  He has worked with both large corporations and startups at different maturity levels.  In his spare time, he enjoys spending time with his family and going out on his bike.",
    avatarsrc: "/images/team/erick_bamaca.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "bamacaerick",
    startDate: "11/06/2023",
  },
  {
    name: "Andres de la Cerda",
    position: "Manager",
    position_id: 3,
    description:
      "Andres is a senior software engineer with more than two decades of experience in software engineering. Throughout his career he has led cross-functional teams to optimize workflows in order to deliver leading-edge technology solutions.  Andres is a natural problem solver, He fosters a culture of innovation and continuous improvement, always empowering his team members to excel. With a strong dedication to quality and customer satisfaction, he drives teams to surpass expectations and create impactful software solutions that resonate with end-users.",
    avatarsrc: "/images/team/andres_de_la_cerda.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "andres-d-73532040",
    startDate: "01/08/2024",
  },
  {
    name: "Luis Guzman",
    position: "Senior Software Engineer",
    position_id: 4,
    description:
      "Luis is a full-stack software engineer who enjoys creating new products or services. He loves combining his analytical thinking and passion for art to build beautiful UI/UX products. In his spare time, Luis enjoys reading, studying philosophy and scuba diving.",
    avatarsrc: "/images/team/luis_guzman.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "luis-guzman-a6546ab3",
    startDate: "03/13/2024",
  },
  {
    name: "Ingrid Perez",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Ingrid is a software engineer who is passionate about exploring new technologies and continuously expanding her skills. She values collaborating with others, learning from different perspectives, and sharing her expertise. She enjoys challenges with diverse technology stacks and embracing new learning opportunities.",
    avatarsrc: "/images/team/ingrid_perez.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "ingridperezm",
    startDate: "07/10/2023",
  },
  {
    name: "Alex Molina",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Alex Molina has more than two decades of expertise in information technology with full stack experience in a number of technologies, databases, and platforms.  He is known for delivering state-of-the-art, scalable architectures and technology solutions. Alex is self-driven, organized, detail-oriented, and outcome-focused. Outside work, he values time with his family and enjoys competitive online gaming.",
    avatarsrc: "/images/team/alex_molina.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "l3xmolina",
    startDate: "06/24/2024",
  },
  {
    name: "Javier Monterroso",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Javier Monterroso is a seasoned UI/UX Designer and Software Engineer.  He has more than 5 years of diverse experience ranging from working on international projects for major corporations to launching successful startups.  Javier combines creativity with technical acumen to deliver insightful user-centric designs and innovative software solutions.",
    avatarsrc: "/images/team/javier_monterroso.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "xvimnt",
    startDate: "06/03/2024",
  },
  {
    name: "Alex Mejicanos",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Alex Mejicanos is a full stack software engineer who likes to build and design solutions that bring clear and immediate value to clients. He enjoys always being up-to-date with the latest technology developments. In his free time, he enjoys learning in general but specially about cybersecurity and pentesting.",
    avatarsrc: "/images/team/alex_mejicanos.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "alexmejicanos",
    startDate: "06/03/2024",
  },
  {
    name: "Mefi Navarro",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Mefi Navarro is a seasoned information technology professional with 18+ years of experience.  He is a certified Scrum Master and Product Owner. Mefi is skilled in managing multiple technologies across complex infrastructures and software architectures (cloud, hybrid, in-house).  He is an avid learner and is committed to continuous self-improvement.  Additionally, he is also a UI/UX enthusiast, counseling psychology enthusiast, and audiophile.",
    avatarsrc: "/images/team/mefi_navarro.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "mefi-boset-navarro-r",
    startDate: "07/01/2024",
  },
  {
    name: "Max García",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Max Garcia is a seasoned software engineer with more than 7 years of experience.  He has led critical mobile and web development projects in a variety of industries using both front and back end technologies and databases.  He is an innovative thinker that likes to get things done.  In his spare time, he loves spending time with his family, learning new things, and a good coffee in the morning.",
    avatarsrc: "/images/team/max_garcia.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "max-jose-garcia-morales-426251191",
    startDate: "05/20/2024",
  },
  {
    name: "Karla Bocaletti",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Karla Bocaletti is an experienced software engineer with over 20+ years of experience.  She is a natural problem solver and is dedicated to learning, sharing knowledge, and fostering collaboration. In her free time, she enjoys reading, doing crafts, and traveling.",
    avatarsrc: "/images/team/karla_bocaletti.jpg",
    className: "team__member team__member--dev-manager",
    linkedIn: "karla-bocaletti-48773a1b",
    startDate: "07/15/2024",
  },
  {
    name: "Marco Galindo",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Marco is an experienced deep learning, neural networks, and full-stack software engineer with a strong background in academia and industry. He is also a published author in renowned international tech journals. In his free time, Marco enjoys listening to music, learning languages, and exploring new cultures.",
    avatarsrc: "/images/team/marco_galindo.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "galindo-marco",
    startDate: "09/03/2024",
  },
  {
    name: "Jorge Jo",
    position: "Software Engineer",
    position_id: 5,
    description:
      "Jorge is a driven and resourceful full-stack software engineer with a passion for staying current with technology. His experience also spans both DevOps and CI/CD which he leverages as part of his commitment to excellence. Outside of work, Jorge enjoys going to the gym and pushing his limits.",
    avatarsrc: "/images/team/jorge_jo.png",
    className: "team__member team__member--dev-manager",
    linkedIn: "/jorgejo",
    startDate: "08/05/2024",
  },






]

teamList.sort(function (a, b) {
  return new Date(a.startDate) - new Date(b.startDate)
})

teamList.sort(function (a, b) {
  return a.position_id - b.position_id
})

export default teamList
